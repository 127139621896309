// @flow

import React from "react";
import { connect } from "react-redux";

import Files from "./Files";
import NotifyGroups from "./NotifyGroups";
import DisableStatus from "./DisableStatus";
import DataProcess from "./DataProcess";
import DisableAutoNumber from "./DisableAutoNumber";
import InviteSenderToConversation from "./InviteSenderToConversation";
import ConfirmationToSender from "./ConfirmationToSender";
import HideProcessInNew from "./HideProcessInNew";
import AddAsOwner from "./AddAsOwner";
import AddAsParticipant from "./AddAsParticipant";
import NextCount from "./NextCount";
import PaginateProcessTable from "./PaginateProcessTable";
import PaginateReports from "./PaginateReports";
import Email from "./Email";
import DueInDays from "./DueInDays";
import Checkbox from "src/components/Checkbox.old";
import { InlineFormGroup, Label } from "src/styles/form";
import { StyledAdvanced } from "./styles";
import type { AppState, WorkflowBuilderDialog } from "src/types";
import { setWorkflowBuiderAttributes } from "src/actions/workflows";

type Props = {
  setWorkflowBuiderAttributes: Function,
  workflowBuilder: WorkflowBuilderDialog
};

const Advanced = ({ workflowBuilder, setWorkflowBuiderAttributes }: Props) => {
  const handleFiles = (files: string) => {
    setWorkflowBuiderAttributes({ files });
  };

  const handleNotifyGroups = (notificationGroups: Array<number>) => {
    setWorkflowBuiderAttributes({ notificationGroups });
  };

  const handleStatus = () => {
    const { settings } = workflowBuilder;
    const { statusDisabled } = settings || {};
    setWorkflowBuiderAttributes({
      settings: { ...settings, statusDisabled: !(statusDisabled || false) }
    });
  };

  const toggleData = () => {
    const { data } = workflowBuilder || {};
    setWorkflowBuiderAttributes({
      data: !(data || false)
    });
  };

  const handleNextCount = (e: any) => {
    const { value } = e.target;
    const settings = workflowBuilder.settings || {};
    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        nextCount: value.length > 0 ? parseInt(value, 10) : null
      }
    });
  };

  const handleAutoNumber = () => {
    const { settings } = workflowBuilder;
    const { hideAutoNumber } = settings || {};
    setWorkflowBuiderAttributes({
      settings: { ...settings, hideAutoNumber: !(hideAutoNumber || false) }
    });
  };

  const handleCreatorIsOwner = () => {
    const { settings } = workflowBuilder;
    const { creatorIsOwner } = settings || {};
    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        creatorIsOwner: !(creatorIsOwner === undefined ? true : creatorIsOwner)
      }
    });
  };

  const handleCreatorIsParticipant = () => {
    const { settings } = workflowBuilder;
    const { creatorIsParticipant } = settings || {};
    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        creatorIsParticipant: !(creatorIsParticipant === undefined
          ? true
          : creatorIsParticipant)
      }
    });
  };

  const handleInviteSenderToConversation = () => {
    const { settings } = workflowBuilder;
    const { senderNotParticipant } = settings || {};
    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        senderNotParticipant: !(senderNotParticipant || false)
      }
    });
  };

  const handleConfirmationToSender = () => {
    const { settings } = workflowBuilder;
    const { noConfirmation } = settings || {};
    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        noConfirmation: !(noConfirmation || false)
      }
    });
  };

  const handleHidingProcessInNew = () => {
    const { settings } = workflowBuilder;
    const { hideProcessInNew } = settings || {};
    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        hideProcessInNew: !(hideProcessInNew || false)
      }
    });
  };

  const handleDueInDays = (e: any) => {
    const { value } = e.target;
    const { settings } = workflowBuilder;

    setWorkflowBuiderAttributes({
      settings: { ...settings, dueIn: value ? parseInt(value, 10) : null }
    });
  };

  const handleArchiving = () => {
    const { settings } = workflowBuilder;

    setWorkflowBuiderAttributes({
      settings: { ...settings, allowArchive: !settings.allowArchive }
    });
  };

  const toggleProcessTablePagination = () => {
    const { settings } = workflowBuilder;

    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        isProcessTablePaginated: !settings.isProcessTablePaginated
      }
    });
  };

  const toggleReportPagination = () => {
    const { settings } = workflowBuilder;

    setWorkflowBuiderAttributes({
      settings: {
        ...settings,
        isReportPaginated: !settings.isReportPaginated
      }
    });
  };

  const { files, notificationGroups, settings, title, data } = workflowBuilder;
  const {
    statusDisabled,
    nextCount,
    hideAutoNumber,
    creatorIsOwner,
    creatorIsParticipant,
    senderNotParticipant,
    noConfirmation,
    dueIn,
    hideProcessInNew,
    allowArchive,
    isProcessTablePaginated,
    isReportPaginated
  } = settings || {};

  return (
    <StyledAdvanced>
      <InlineFormGroup>
        <Label>
          <Checkbox
            id="allowArchive"
            checked={allowArchive}
            handleChange={handleArchiving}
          />
          Allow adhoc archiving
        </Label>
      </InlineFormGroup>
      <Files files={files} handleFiles={handleFiles} />
      <NotifyGroups
        notificationGroups={notificationGroups}
        handleNotifyGroups={handleNotifyGroups}
      />
      <DisableStatus
        statusDisabled={statusDisabled}
        handleStatus={handleStatus}
      />

      <DataProcess value={data} handleValue={toggleData} />

      <DisableAutoNumber
        hideAutoNumber={hideAutoNumber}
        handleAutoNumber={handleAutoNumber}
      />

      <AddAsOwner
        creatorIsOwner={creatorIsOwner}
        handleCreatorIsOwner={handleCreatorIsOwner}
      />
      <AddAsParticipant
        creatorIsParticipant={creatorIsParticipant}
        handleCreatorIsParticipant={handleCreatorIsParticipant}
      />
      <InviteSenderToConversation
        senderNotParticipant={senderNotParticipant}
        handleInviteSenderToConversation={handleInviteSenderToConversation}
      />
      <ConfirmationToSender
        noConfirmation={noConfirmation}
        handleConfirmationToSender={handleConfirmationToSender}
      />
      <HideProcessInNew
        handleHidingProcessInNew={handleHidingProcessInNew}
        hideProcessInNew={hideProcessInNew ?? false}
      />
      <PaginateProcessTable
        isProcessTablePaginated={isProcessTablePaginated}
        toggleProcessTablePagination={toggleProcessTablePagination}
      />

      <PaginateReports
        isReportPaginated={isReportPaginated}
        toggleReportPagination={toggleReportPagination}
      />
      <DueInDays handleDueInDays={handleDueInDays} dueIn={dueIn} />
      <NextCount nextCount={nextCount} handleNextCount={handleNextCount} />
      <Email title={title} />
    </StyledAdvanced>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  workflowBuilder: app.workflow.builderDialog
});

export default connect(mapStateToProps, { setWorkflowBuiderAttributes })(
  Advanced
);
