// @flow

import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Flex,
  Box,
  List as StyledList,
  Popover,
  PopoverContent
} from "@chakra-ui/react";
import { connect } from "react-redux";
import { useDebounce } from "use-debounce";
import * as R from "ramda";
import { Input } from "@chakra-ui/react";
import ConversationList from "./ConversationList";
import PinnedList from "./PinnedList";
import SuggestedList from "./Suggested";
import QuickFilteredList from "./QuickFilters";
import List from "./List";
import {
  getFilteredChatroomCount,
  getCurrentActiveFilterName
} from "src/reducers";
import Icons from "src/icons";
import { hideFilterModal, searchQuery } from "src/actions/header";
import { setFilter } from "src/actions/filter";
import { showView } from "src/actions";
import type { AppState } from "src/types";
import defaultFilter from "src/constants/defaultFilter";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";

type Props = {
  conversationModal: boolean,
  _hideFilterModal: Function,
  _searchQuery: Function,
  _setfilter: Function,
  favourites: Object,
  count: number,
  currentFilterName: string,
  _showView: Function
};

const ConversationSelector = (props: Props) => {
  const searchConversationsRef = useRef();
  const [inputSearchQuery, setSearchQuery] = useState("");
  const [value] = useDebounce(inputSearchQuery, 80);

  const {
    conversationModal,
    _hideFilterModal,
    _searchQuery,
    _setfilter,
    favourites,
    count,
    currentFilterName,
    _showView
  } = props;

  useEffect(() => {
    _searchQuery(inputSearchQuery);
  }, [inputSearchQuery, _searchQuery]);

  const HandleInputChange = useCallback(
    (event: any) => {
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  const emptySearchField = useCallback(() => {
    setSearchQuery("");
  }, [setSearchQuery]);

  const hideConversation = useCallback(() => {
    _hideFilterModal();
    emptySearchField();
  }, [_hideFilterModal, emptySearchField]);

  const openSavedFavourites = useCallback(() => {
    _setfilter(favourites, null, "Favourite");
    _showView("Favourites");
    hideConversation();
    emptySearchField();
  }, [_setfilter, emptySearchField]);

  const openAllConversations = useCallback(() => {
    _setfilter(defaultFilter.everything, "default", "All Records");
    _showView("Everything");
    hideConversation();
    emptySearchField();
  }, []);

  const filterByEveryoneEnabled = useComponentPermission(
    componentPermissions.filterEveryone
  );

  return (
    <Popover
      isOpen={conversationModal}
      onClose={hideConversation}
      initialFocusRef={searchConversationsRef}
    >
      <PopoverContent
        sx={{ background: "white" }}
        top="3.5rem"
        left="4rem"
        width="388px"
        _focus={{ border: 0 }}
        boxShadow="rgb(0 0 0 / 10%) 0px 2px 7px 0px"
        borderRadius={radius.medium}
        fontSize="16px"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          onClick={hideConversation}
          fontSize="md"
          px="2"
          pt="2"
        >
          <strong>
            {currentFilterName}
            <Box display="inline" pl="2" fontWeight="normal" color="gray.400">
              ({count})
            </Box>
          </strong>
          <Icons type="caretUp" />
        </Flex>

        <Input
          m="2"
          w="95%"
          size="sm"
          type="text"
          placeholder="Search type"
          name="search"
          ref={searchConversationsRef}
          value={inputSearchQuery}
          onChange={HandleInputChange}
          autoFocus
          autoComplete="off"
          borderColor={colors.grey2}
        />

        <StyledList mt="0" p="0" maxHeight="30em" overflow="auto">
          {R.includes(value.toLowerCase(), "all conversations") &&
          filterByEveryoneEnabled ? (
            <List title="All Records" handleClick={openAllConversations} />
          ) : null}

          {R.includes(value.toLowerCase(), "favourites") ? (
            <List
              title="Favourites"
              handleClick={openSavedFavourites}
              iconType="star"
            />
          ) : null}

          {value.length > 0 ? null : (
            <SuggestedList emptySearchField={emptySearchField} />
          )}

          <PinnedList
            emptySearchField={emptySearchField}
            hideConversation={hideConversation}
          />
          <ConversationList
            emptySearchField={emptySearchField}
            searchValue={value}
          />
          {value?.length === 0 ? null : (
            <QuickFilteredList
              emptySearchField={emptySearchField}
              hideConversation={hideConversation}
            />
          )}
        </StyledList>
      </PopoverContent>
    </Popover>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  conversationModal: app.header.conversationModal,
  favourites: defaultFilter.favourites,
  count: getFilteredChatroomCount(app),
  currentFilterName: getCurrentActiveFilterName(app)
});

export default connect(mapStateToProps, {
  _hideFilterModal: hideFilterModal,
  _searchQuery: searchQuery,
  _setfilter: setFilter,
  _showView: showView
})(ConversationSelector);
