// @flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button
} from "@chakra-ui/react";

import {
  setWorkflowChanges,
  setWorkflowChangesAndRedirect
} from "src/actions/workflows";
import {
  getNavigationBlock,
  getWorkflowBuilderTitle,
  getBuilderDialogStatus
} from "src/reducers";
import { dataStages } from "src/constants";

const ExitAlert = () => {
  const dispatch = useDispatch();
  const { message, canLeave } = useSelector(({ app }) =>
    getNavigationBlock(app)
  );
  const workflowTitle = useSelector(({ app }) => getWorkflowBuilderTitle(app));
  const status = useSelector(({ app }) => getBuilderDialogStatus(app));
  const { title, body, cancelText, submitText } = message || {};
  const [isOpen, setOpen] = useState(false);
  const isLoading = status === dataStages.updating;

  useEffect(() => {
    if ((title && title === "Save Changes") || title === "Create Process")
      setOpen(true);
  }, [message]);

  useEffect(() => {
    if (isOpen && status === dataStages.updated) {
      onExit();
    }
  }, [status]);

  const onClose = () => {
    setOpen(false);
    canLeave && canLeave(false);
  };

  const onExit = () => {
    setOpen(false);
    canLeave && canLeave(true);
  };

  const saveAndExit = () => {
    if (title === "Create Process") {
      dispatch(setWorkflowChangesAndRedirect());
    } else {
      dispatch(setWorkflowChanges());
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title || ""}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{body || ""}</ModalBody>

        <ModalFooter>
          <Button disabled={isLoading} variant="ghost" mr={3} onClick={onExit}>
            {cancelText || "Exit"}
          </Button>
          <Button
            disabled={!workflowTitle || isLoading}
            isLoading={isLoading}
            colorScheme="blue"
            onClick={saveAndExit}
          >
            {submitText || "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExitAlert;
