// @flow

import * as colors from "src/styles/constants/colors";

// mapping from internal names to display names
// Singular, Plural, 'All' Plural
const conversationTypes = {
  group: ["Feed"],
  workflow: ["Process", "Processes", "All Processes"],
  approval: ["Approval", "Approvals", "All Approvals"],
  task: ["Task", "Tasks", "All Tasks"],
  conversation: ["Conversation", "Conversations", "All Records"],
  direct: ["Direct messages"]
};

const conversations = [
  {
    value: 0,
    label: "workflow",
    icon: "workflow"
  },
  {
    value: 1,
    label: "task",
    icon: "task"
  },
  {
    value: 2,
    label: "approval",
    icon: "approval"
  },
  {
    value: 3,
    label: "group",
    icon: "group"
  },
  {
    value: 4,
    label: "conversation",
    icon: "conversation"
  },
  {
    value: 5,
    label: "direct",
    icon: "chat"
  }
];

const processColumnNames = {
  seqNo: "#",
  title: "Conversation name",
  priority: "Priority",
  status: "Status",
  parent: "Parent",
  childCount: "Children",
  createdAt: "Date created",
  updatedAt: "Date updated",
  completedAt: "Date completed",
  age: "Age (days)",
  creator: "Creator",
  owner: "Owner",
  members: "Participants",
  description: "Description",
  completedBy: "Completed by",
  dueDate: "Due date"
};

export const formColumnNames = {
  seqNo: "#",
  chatroomId: "Associated conversations",
  templateId: "Process",
  creator: "Creator",
  createdBy: "Added by",
  createdAt: "Date created",
  childCount: "Children",
  address: "Form Id"
};

const chatBlockColors = {
  group: colors.yellow,
  conversation: colors.orange,
  approval: colors.inkLighter,
  task: colors.greenDark,
  direct: "",
  workflow: ""
};

export const autoReminderType = {
  // prettier-ignore
  "1": "of inactivity",
  // prettier-ignore
  "2": "of inactivity from due date",
  // prettier-ignore
  "3": "of inactivity from last reminder",
  // prettier-ignore
  "4": "from due date",
  // prettier-ignore
  "5": "from created date",
  "6": "before due date"
};

export {
  conversationTypes,
  conversations,
  processColumnNames,
  chatBlockColors
};

export const checklistFieldNames = {
  text: "Text",
  number: "Number",
  date: "Date",
  user: "User",
  section: "Section",
  subSection: "Subsection",
  conversation: "Parent Conversation",
  childConversation: "Child Conversation",
  select: "Picklist",
  pdf: "Generate PDF",
  file: "File Upload",
  form: "Form",
  approval: "Approval",
  revision: "Revision",
  link: "Linked field",
  // Deprecated Fields
  chatPickList: "Conversation (Deprecated)",
  workflow: "Process (Deprecated)",
  task: "Task (Deprecated)",
  group: "Feed (Deprecated)",
  uploadFieldsFromCSV: "Upload fields from CSV",
  uploadFieldsJSON: "Upload fields from JSON"
};

export const checklistFieldMeta = {
  text: ["green.300", "capitalize"],
  number: ["cyan.400", "hashmark"],
  date: ["yellow.600", "calendar"],
  user: ["pink.300", "people"],
  section: ["#212B36", "section"],
  subSection: ["#212B36", "subSection"],
  conversation: ["blue.300", "layers"],
  childConversation: ["blue.300", "layers"],
  select: ["orange.300", "listNumbers"],
  pdf: ["red.300", "fileArticle"],
  file: ["yellow.400", "attachClip2"],
  form: ["gray.400", "layout"],
  approval: ["purple.300", "edit2"],
  revision: ["purple.300", "revision"],
  chatPickList: ["purple.300", "edit2"],
  workflow: ["purple.300", "edit2"],
  task: ["purple.300", "edit2"],
  group: ["purple.300", "edit2"],
  link: ["teal.300", "anchor"]
};

export const userSegmentType = {
  age_on_platform: "has used the platform",
  group_member: "Is part of group",
  not_group_member: "Isn’t part of group"
};

export const checklistFieldIcons = {
  text: {
    icon: "capitalize",
    color: "green.300",
    hexCode: "#68D391",
    description: "Free type text field"
  },
  number: {
    icon: "hashmark",
    color: "cyan.400",
    hexCode: "#0BC5EA",
    description: "Enter a number. Can be used to analyse totals"
  },
  date: {
    icon: "calendar",
    color: "yellow.600",
    hexCode: "#B7791F",
    description: "User entered date. Manual or date picker."
  },
  user: {
    icon: "people",
    color: "pink.300",
    hexCode: "#F687B3",
    description: "Select a user within your contacts or organization"
  },
  section: {
    icon: "section",
    color: "green.300",
    hexCode: "#D53F8C",
    description: "Divide the checklist using prominent collapsible sections."
  },
  subSection: {
    icon: "subSection",
    color: "green.300",
    hexCode: "#805AD5",
    description: "Add a collapsible sub-section within a section"
  },
  conversation: {
    icon: "parentLayers",
    color: "blue.300",
    hexCode: "#276749",
    description: "Link another process as a parent conversation."
  },
  childConversation: {
    icon: "layers",
    color: "blue.300",
    hexCode: "#63B3ED",
    description: "Link another process as a child conversation."
  },
  select: {
    icon: "listNumbers",
    color: "orange.300",
    hexCode: "#F6AD55",
    description: "Drop-down selection of items. Optional: Users can add items."
  },
  pdf: {
    icon: "fileArticle",
    color: "red.300",
    hexCode: "#FC8181",
    description: "Generate a printable pdf  of the checklist using a template."
  },
  file: {
    icon: "attachClip2",
    color: "yellow.400",
    hexCode: "#ECC94B",
    description: "Attach / upload documents or images to the field."
  },
  form: {
    icon: "layout",
    color: "gray.400",
    hexCode: "#A0AEC0",
    description: "Add a pre-configured form. Useful for multiple line items."
  },
  approval: {
    icon: "edit2",
    color: "purple.300",
    hexCode: "#B794F4",
    description: "Apply digital signatures to processes and documents."
  },
  revision: {
    icon: "currentRevision",
    color: "purple.300",
    hexCode: "#805AD5",
    description: "Create and manage revisions of conversations"
  },
  link: {
    icon: "anchor",
    color: "teal.300",
    hexCode: "#4FD1C5",
    description: "Link to another process (both ways)."
  },
  uploadFieldsFromCSV: {
    icon: "excel",
    description: "Upload an excel checklist sheet to quickly create a checklist"
  }
};
